<template>
    <div class="loading-container">
        <div class="spinner"></div>
        <p>Autenticando, por favor espera...</p>
    </div>
</template>

<script>
import { AUTH_REQUEST_MICROSOFT } from "../store/actions/auth";
export default {
    name: "CallBack",
    data() {
        return {
            code: null
        };
    },
    mounted() {
        this.code = this.$route.query.code;

        if (!this.code) {
            this.$router.push("/login");
            return;
        }
        this.handleAuthentication();
    },
    methods: {
        handleAuthentication() {
            this.loading = true;
            this.error = false;

            this.$store
                .dispatch(AUTH_REQUEST_MICROSOFT, this.code)
                .then(() => {
                    setTimeout(() => {
                        this.loading = false;
                        this.$router.push("/");
                    }, 50);
                })
                .catch(() => {
                    this.loading = false;
                    this.error = true;
                    //console.console.log(err);
                    // ("Error durante la autenticación:", err);
                    this.$router.push("/login");
                });
        },
    },

};
</script>
<style scoped>
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>