<template>
    <div>
        <Callback/>
    </div>
</template>

<script>
import Callback from '../components/Callback.vue';
export default {
    name: "callback",
    components: {
        Callback
    }
}
</script>